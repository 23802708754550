import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage {
        heroTitle
        heroContent {
          heroContent
        }
        heroLinkLabel
        heroLinkUrl
        secondaryHeroButtonLabel
        secondaryHeroButtonUrl
        heroImage1 {
          file {
            url
          }
        }
        heroImage2 {
          file {
            url
          }
        }
      }
    }
  `)
  const {
    heroTitle,    
    heroContent,
    heroLinkLabel,
    secondaryHeroButtonUrl,
    secondaryHeroButtonLabel,
    heroLinkUrl,
    heroImage1,
    heroImage2,
  } = data.contentfulHomePage
  return (
    <section className="hero-sec">
      <div className="container">
        <div className="row">
          <div className="col col-md-6 hero-sec-content d-flex align-items-center">
            <div>
              <h1>{heroTitle}</h1>
              <p>{heroContent.heroContent}</p>
              <div className="heroBtnWrapper">
                <a href={heroLinkUrl}>
                  <button type="button" className="btn d-btn">
                    {heroLinkLabel}
                  </button>
                </a>
                <a href={secondaryHeroButtonUrl}>
                  <button type="button" className="btn d-btn">
                    {secondaryHeroButtonLabel}
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col col-md-6 hero-sec-image">
            <div className="hero-sec-img1">
              <img src={heroImage1.file.url} alt="#" />
            </div>
            <div className="hero-sec-img2">
              <img src={heroImage2.file.url} alt="#" />
            </div>
            {/* <!-- <img src="./assets/images/hero-img.jpg" /> --> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
