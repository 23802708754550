import React, { useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { kebabCase } from "lodash"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Visit from "../components/shared/visit"

const Articles = props => {
  const data = useStaticQuery(graphql`
    {
      tags: allContentfulResourceArticles {
        nodes {
          keywords
        }
      }
      tagsShort: allContentfulResourceArticlesShort {
        nodes {
          keywords
        }
      }
      tagsFaq: allContentfulResourceArticleFaq {
        nodes {
          keywords
        }
      }
    }
  `)

  useEffect(() => {
    document.querySelector("body").classList.add("resource-center-page")
  }, [])

  const { group, index, pageCount } = props.pageContext

  const isFirst = index === 1
  const isLast = index === pageCount
  const prevPage =
    index - 1 === 1 ? "/resources" : "/resources/" + (index - 1).toString()
  const nextPage = "/resources/" + (index + 1).toString()

  const tagsCombine = data.tags.nodes.concat(
    data.tagsShort.nodes,
    data.tagsFaq.nodes
  )

  const uniqueTags = Array.from(
    new Set(
      tagsCombine.reduce(
        (carry, current) => [...carry, ...current.keywords],
        []
      )
    )
  ).sort()
  const pathName = typeof window !== "undefined" && window.location.pathname
  return (
    <Layout>
      <Seo title="Resource Center" />
      <section className="article-hero-sec resource-hero-sec">
        <div className="container">
          <div
            className="row"
            style={{
              backgroundImage: "url(./assets/images/resources-header.jpg)",
            }}
          >
            <div className="col">
              <h6>
                <span>Home</span> | <span>Resource Center</span>
              </h6>
              <h2>Resource Center</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="resource-selection-sec">
        <div className="container">
          <div className="row text-center"></div>
          <div className="row text-center resource-slider-row1">
            <div className="col">
              <h5>Pet Type:</h5>
            </div>
            <div className="col">
              <Link
                to="/resources"
                className={pathName === "/resources" && "active"}
              >
                <span>All</span>
              </Link>
              <Link to="/resources/tag/dogs">
                <span>Dog</span>
              </Link>
              <Link to="/resources/tag/cats">
                <span>Cat</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="resource-selection-sec">
        <div className="container">
          <div className="row text-center"></div>
          <div className="row text-center resource-slider-row1">
            <div className="col">
              <h5>Topic:</h5>
            </div>
            <div className="col resource-slider-n">
              <Link
                to="/resources"
                className={pathName === "/resources" && "active"}
              >
                <span>All</span>
              </Link>
              {uniqueTags.map((tag, i) => {
                return (
                  tag !== "Cats" &&
                  tag !== "cats" &&
                  tag !== "dogs" &&
                  tag !== "Dogs" && (
                    <Link to={`/resources/tag/${kebabCase(tag)}`} key={i}>
                      <span>{tag}</span>
                    </Link>
                  )
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="resource-main-content">
        <div className="container">
          <div className="row">
            {group.map(article => (
              <div className="col col-md-4" key={article.node.id}>
                <div className="resource-main-box">
                  <Link to={`/resources/${article.node.slug}`}>
                    {/* <img src={article.node.image.file.url} alt="#" /> */}
                    {
          // Safely check whether article.image and article.image.file exist
          article?.node?.image?.file?.url && (
            <img src={article.node.image.file.url} alt={article.title || ""} />
          )
        }
                    
                  </Link>
                  <div className="resource-main-box-inner">
                    <Link to={`/resources/${article.node.slug}`}>
                      <h5>{article.node.category}</h5>
                    </Link>
                    <Link to={`/resources/${article.node.slug}`}>
                      <h4>{article.node.title}</h4>
                    </Link>
                    <Link to={`/resources/${article.node.slug}`}>
                      <p>{article.node.excerpt.excerpt}</p>
                    </Link>
                    <Link
                      to={`/resources/${article.node.slug}`}
                      className="link-btn"
                    >
                      Read More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.142"
                        height="14.767"
                        viewBox="0 0 22.142 14.767"
                      >
                        <path
                          id="Icon_ionic-ios-arrow-round-forward"
                          data-name="Icon ionic-ios-arrow-round-forward"
                          d="M22,11.534a1,1,0,0,0-.008,1.415l4.676,4.684H8.867a1,1,0,0,0,0,2H26.656L21.98,24.317a1.012,1.012,0,0,0,.008,1.415,1,1,0,0,0,1.407-.008l6.337-6.383h0a1.123,1.123,0,0,0,.208-.315.954.954,0,0,0,.077-.385,1,1,0,0,0-.285-.7L23.4,11.557A.98.98,0,0,0,22,11.534Z"
                          transform="translate(-7.875 -11.252)"
                          fill="#ff0245"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row resource-main-content-row1">
            <div className="col d-flex justify-content-center">
              {!isFirst ? (
                <Link to={prevPage}>
                  <button type="button" className="resource-main-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.361"
                      height="11.92"
                      viewBox="0 0 7.361 11.92"
                    >
                      <path
                        id="left-arrow"
                        d="M10.4,11.76l4.559,4.549,4.559-4.549,1.4,1.4-5.96,5.96L9,13.161Z"
                        transform="translate(19.121 -9) rotate(90)"
                        fill="#b6b6b6"
                      />
                    </svg>
                  </button>
                </Link>
              ) : null}

              <ul className="d-flex">
                {Array.from({ length: pageCount }, (_, i) => (
                  <li className={index === i + 1 && "active"} key={i}>
                    <Link
                      to={`${
                        i === 0 ? "/resources/" : "/resources/" + (i + 1)
                      }`}
                    >
                      <button type="button">{i + 1}</button>
                    </Link>
                  </li>
                ))}
              </ul>
              {!isLast ? (
                <Link to={nextPage}>
                  <button className="resource-main-arrow" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.361"
                      height="11.92"
                      viewBox="0 0 7.361 11.92"
                    >
                      <path
                        id="right-arrow"
                        d="M10.4,11.76l4.559,4.549,4.559-4.549,1.4,1.4-5.96,5.96L9,13.161Z"
                        transform="translate(-11.76 20.92) rotate(-90)"
                        fill="#b6b6b6"
                      />
                    </svg>
                  </button>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <Visit data={group[0].node.scheduleAVisit} />
    </Layout>
  )
}

export default Articles
