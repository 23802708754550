import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import MetaCards from "../../components/common/meta-cards"
import Hero from "../../components/surgeries/hero"
import SurgeriesSection from "../../components/surgeries/surgeries"
import HelpfulLinks from "../../components/shared/helpful_links"
import Testimonials from "../../components/shared/testimonials"
import TipsFromTeam from "../../components/shared/tips_from_team"
import Visit from "../../components/shared/visit"

const Surgeries = ({ data }) => {
  useEffect(() => {
    document.querySelector("body").classList.add("surgeries")
  }, [])
  return (
    <Layout>
      <Seo title="Surgeries" />
      <MetaCards data={data.contentfulSurgeriesPage.webMeta}/>
      <Hero />
      <SurgeriesSection />
      <HelpfulLinks
        data={data.contentfulSurgeriesPage.additionalInfoSection}
        surgery={true}
      />
      <Testimonials data={data.contentfulSurgeriesPage} about={true} />
      <TipsFromTeam
        data={data.contentfulSurgeriesPage.tipsFromTeam}
        services={true}
      />
      <Visit data={data.contentfulSurgeriesPage.scheduleAVisit} />
    </Layout>
  )
}

export default Surgeries

export const query = graphql`
  {
    contentfulSurgeriesPage {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      additionalInfoSection {
        image {
          file {
            url
          }
        }
        title
        subTitle
        links {        
          ... on ContentfulLinkTemplate {
            id
            label
            url
          }
        }
        content {
          content
        }
      }
      testimonialTitle
      testimonials {
        id
        testimonial {
          testimonial
        }
        client
      }
       tipsFromTeam {
        title
        content
        tips {
          id
          image {
            file {
              url
            }
          }
          shortDescription
          label
          url
        }
      }
      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
        secondaryButtonUrl
        secondaryButtonLabel
      }
    }
  }
`
