import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import MetaCards from "../../components/common/meta-cards"
import Hero from "../../components/services/hero"
import CoreServices from "../../components/services/core_services"
import HelpfulLinks from "../../components/shared/helpful_links"
import Testimonials from "../../components/shared/testimonials"
import TipsFromTeam from "../../components/shared/tips_from_team"
import Visit from "../../components/shared/visit"

const Services = ({ data }) => {
  return (
    <Layout>
      <Seo title="Services" />
      <MetaCards data={data.contentfulServicesPage.webMeta}/>
      <Hero />
      <CoreServices />
      <HelpfulLinks data={data.contentfulServicesPage.helpfulLinks} />
      <Testimonials data={data.contentfulServicesPage} about={true} />
      <TipsFromTeam
        data={data.contentfulServicesPage.tipsFromTeam}
        services={true}
      />
      <Visit data={data.contentfulServicesPage.scheduleAVisit} />
    </Layout>
  )
}

export default Services

export const query = graphql`
  {
    contentfulServicesPage {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      helpfulLinks {
        id
        image {
          file {
            url
          }
        }
        title
        subTitle
        links {        
          ... on ContentfulLinkTemplate {
            id
            label
            url
          }
        }
      }
      testimonialTitle
      testimonials {
        id
        testimonial {
          testimonial
        }
        client
      }
      tipsFromTeam {
        title
        content
        tips {
          id
          shortDescription
          image {
            file {
              url
            }
          }
          label
          url
        }
      }
      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
        secondaryButtonUrl
        secondaryButtonLabel
      }
    }
  }
`