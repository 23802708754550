import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import MetaCards from "../components/common/meta-cards"
import Hero from "../components/article/hero"
import FeaturedSection from "../components/article/feature"
import FeaturedMobile from "../components/article/feature-mobile"
import ArticleContent from "../components/article/content"
import FAQs from "../components/article/faqShort"
import TipsFromTeam from "../components/shared/tips_from_team"
import Visit from "../components/shared/visit"

const Article = props => {
  useEffect(() => {
    document.querySelector("body").classList.add("resource-inner-page")
  }, [])

  // Destructure from props
  const { articles } = props.data
  // Specifically destructure hideAdditionalContents
  const { hideAdditionalContents, additionalContent } = articles

  return (
    <Layout>
      <Seo title={articles.title} />
      <MetaCards data={articles.webMeta} />
      <Hero articles={articles} />
      <FeaturedSection articles={articles} />
      <FAQs articles={articles} />

      {!hideAdditionalContents && (
      <div className="additional-content-sec">
        {additionalContent.map(item => (
          <ArticleContent key={item.id} item={item} />
        ))}
      </div>
    )}
      <div className="mobileOnly">
        <FeaturedMobile articles={articles} />
      </div>
      <Visit data={articles.scheduleAVisit} />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ($slug: String!) {
    articles: contentfulResourceArticleFaq(slug: { eq: $slug }) {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      id
      image {
        file {
          url
        }
      }
      category
      title
      slug
      excerpt {
        excerpt
      }
      date
      keywords
      introText {
        raw
      }
      rightSideBar {
        title
        links {
          ... on ContentfulLinkTemplate {
            id
            label
            url
          }
        }
      }
      newPatients {
        image {
          file {
            url
          }
        }
        title
        content {
          content
        }
        linkLabel
        linkUrl
      }

      faqs {
        title
        content {
          content
        }
        faqs {
          id
          question
          theAnswer {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
                description
              }
            }
          }
        }
      }
      hideAdditionalContents
      additionalContent {
        id
        fullWidth
        imageToLeft
        fullHeightImage
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              description
            }
          }
        }
      }
      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
        secondaryButtonUrl
        secondaryButtonLabel
      }
    }
  }
`