import React, { useState } from "react"
import Select from "react-select"
import ReferralLinks from "../shared/referral_links"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ReCAPTCHA from "react-google-recaptcha"

let map =
  "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJBxDqmHdRO4gRTmH0qrpCGfU&zoom=10&key=AIzaSyBSWlWOQ-c_ViKBLxv_MgjXB-BzxI33efo"

const customStyles = {
  control: (base, state) => ({
    ...base,
    // background: "transparent",
    border: "2px solid #fff",
    color: "#000",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#ff0245" : "#ff0245",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ff0245" : "#ff0245",
      boxShadow: state.isFocused ? null : null,
      color: state.isFocused ? "brown" : "grey",
    },
  }),
  menu: (base, state) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    border: "1px solid #ff0245",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    color: "#262b4a",
    color: state.isFocused ? "orange" : "#ff0245",
  }),
}

const ReferralForm = ({ data }) => {
  const [capVal, setCapVal] = useState()
  const [values, setValues] = useState({
    page: "book-appt",
    fullName: "",
    email: "",
    phone: "",
    petsName: "",
    species: "",
    breed: "",
    vaccinationStatus: "",
    apptReason: "",
    message: "",
  })
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const {
    fullName,
    email,
    phone,
    petsName,
    species,
    breed,
    vaccinationStatus,
    apptReason,
    message,
  } = values

  const [capErr, setCapErr] = useState("")
  const [fullNameErr, setfullNameErr] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const [phoneErr, setPhoneErr] = useState("")
  const [petsNameErr, setPetsNameErr] = useState("")
  const [breedErr, setBreedErr] = useState("")
  const [apptReasonErr, setApptReasonErr] = useState("")
  const [vaccinationStatusErr, setVaccinationStatusErr] = useState("")
  const [speciesErr, setSpeciesErr] = useState("")
  const [messageErr, setMessageErr] = useState("")

  const speciesOptions = [
    { value: "Dog", label: "Dog" },
    { value: "Cat", label: "Cat" },
  ]

  const apptOptions = [
    { value: "Emergency & Critical Care", label: "Emergency & Critical Care" },
    { value: "Surgery", label: "Surgery" },
    { value: "New Client/Patient Visit", label: "New Client/Patient Visit" },
    { value: "Sick Pet Exam", label: "Sick Pet Exam" },
    { value: "Annual Pet Exam", label: "Annual Pet Exam" },
    { value: "Nail Trim", label: "Nail Trim" },
    {
      value: "Other (Explain Service details in textbox below)",
      label: "Other (Explain Service details in textbox below)",
    },
  ]

  const vaccinationStatusOptions = [
    { value: "Current", label: "Current" },
    { value: "Pastdue", label: "Pastdue" },
    { value: "Unsure", label: "Unsure" },
  ]

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const onChangeSpecies = selectedOption => {
    setValues({ ...values, species: selectedOption })
  }
  const onChangeApptReason = selectedOption => {
    setValues({ ...values, apptReason: selectedOption })
  }

  const onChangeVaccinationStatus = selectedOption => {
    setValues({ ...values, vaccinationStatus: selectedOption })
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const submitForm = async () => {
    setCapErr("")
    setfullNameErr("")
    setEmailErr("")
    setPhoneErr("")
    setPetsNameErr("")
    setSpeciesErr("")
    setBreedErr("")
    setApptReasonErr("")
    setMessageErr("")
    setSuccess(false)

    if (!capVal) {
      setCapErr("Are you a robot?")
    }
    if (!fullName) {
      setfullNameErr("Full Name is required")
    }
    if (!phone) {
      setPhoneErr("Phone Number is required")
    }
    if (!email) {
      setEmailErr("Email is required")
    }
    if (!petsName) {
      setPetsNameErr("Pet's Name is required")
    }
    if (!breed) {
      setBreedErr("Pet's Breed is required")
    }
    if (!species.value) {
      setSpeciesErr("Pet's Species is required")
    }
    if (!apptReason.value) {
      setApptReasonErr("Appointment Reason is required")
    }
    if (!vaccinationStatus.value) {
      setVaccinationStatusErr("Vaccination Status is required")
    }
    if (!message) {
      setMessageErr("Message is required")
    }
    if (!validateEmail(email)) {
      setEmailErr("Please provide a valid email")
    }
    if (
      !capVal ||
      !fullName ||
      !phone ||
      !email ||
      !breed ||
      !vaccinationStatus ||
      !petsName ||
      !species.value ||
      !message
    ) {
      return
    }
    try {
      const res = await fetch("/.netlify/functions/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok) {
        setSuccess(true)
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }

  return (
    <section className="referral-form-sec contact-form-sec">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 style={{ textAlign: "center" }}>Request A Service </h2>
            <p></p>
          </div>
          <div className="col">
            <div className="row">
              <div className="col col-sm-10 referral-form-sec-inner">
                <label>
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Full Name * "
                    value={fullName}
                    onChange={handleChange}
                  />
                  {fullNameErr && <p>{fullNameErr}</p>}
                </label>
                <label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address *"
                    value={email}
                    onChange={handleChange}
                  />
                  {emailErr && <p>{emailErr}</p>}
                </label>
                <label>
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Cell Phone *"
                    value={phone}
                    onChange={handleChange}
                  />
                  {phoneErr && <p>{phoneErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="petsName"
                    placeholder="Pet's Name *"
                    value={petsName}
                    onChange={handleChange}
                  />
                  {petsNameErr && <p>{petsNameErr}</p>}
                </label>
                <label>
                  <Select
                    options={speciesOptions}
                    className={`customDropDown zIndexFixHigh`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Species *`}
                    name="species"
                    value={species}
                    onChange={onChangeSpecies}
                  />
                  {speciesErr && <p>{speciesErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="breed"
                    placeholder="Breed *"
                    value={breed}
                    onChange={handleChange}
                  />
                  {breedErr && <p>{breedErr}</p>}
                </label>
                <label>
                  <Select
                    options={apptOptions}
                    className={`customDropDown`}
                    classNamePrefix={`customDropDown`}
                    styles={customStyles}
                    required
                    placeholder={`Appointment Reason? *`}
                    name="apptReason"
                    value={apptReason}
                    onChange={onChangeApptReason}
                  />
                  {apptReasonErr && <p>{apptReasonErr}</p>}
                </label>
                <label>
                  <Select
                    options={vaccinationStatusOptions}
                    className={`customDropDown`}
                    classNamePrefix={`customDropDown`}
                    styles={customStyles}
                    required
                    placeholder={`Vaccination Status *`}
                    name="vaccinationStatus"
                    value={vaccinationStatus}
                    onChange={onChangeVaccinationStatus}
                  />
                  {vaccinationStatusErr && <p>{vaccinationStatusErr}</p>}
                </label>

                <label>
                  <textarea
                    name="message"
                    placeholder="How Can We Help You?*"
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  {messageErr && <p>{messageErr}</p>}
                </label>
                <label>
                  <ReCAPTCHA
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    onChange={val => setCapVal(val)}
                  />
                  {capErr && <p>{capErr}</p>}
                </label>
                <div className="referral-link-btn text-center">
                  <button type="button" className="d-btn" onClick={submitForm}>
                    Submit
                  </button>
                  {error && <p>{error}</p>}
                  {success && (
                    <p>
                      Thank you for contacting us! We'll be in touch with you
                      soon.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-sec2">
        <div className="container">
          <div className="row">
            <div className="col col-md-4 d-flex align-items-center contact-sec2-col">
              <div>
                <div className="contact-sec2-inner">
                  <h3>Address</h3>
                  <p className="d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.352"
                      height="23.136"
                      viewBox="0 0 17.352 23.136"
                    >
                      <path
                        id="address-icon"
                        d="M8.676,0A8.665,8.665,0,0,0,0,8.676c0,5.784,8.676,14.46,8.676,14.46s8.676-8.676,8.676-14.46A8.665,8.665,0,0,0,8.676,0Zm0,2.892A5.784,5.784,0,1,1,2.892,8.676,5.782,5.782,0,0,1,8.676,2.892Z"
                        fill="#ff0245"
                      />
                    </svg>
                    <span>43439 Michigan Avenue Canton, MI 48188</span>
                  </p>
                </div>
                <div className="contact-sec2-inner">
                  <h3>Phone & Hours</h3>
                  <p className="d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.136"
                      height="23.136"
                      viewBox="0 0 23.136 23.136"
                    >
                      <path
                        id="phone-icon"
                        d="M28.278,22.494a14.662,14.662,0,0,1-4.589-.733,1.29,1.29,0,0,0-1.3.315L19.557,24.91a19.39,19.39,0,0,1-8.47-8.464l2.828-2.834a1.29,1.29,0,0,0,.315-1.3,14.7,14.7,0,0,1-.733-4.6,1.281,1.281,0,0,0-1.285-1.285h-4.5A1.281,1.281,0,0,0,6.427,7.712,21.849,21.849,0,0,0,28.278,29.563a1.281,1.281,0,0,0,1.285-1.285v-4.5a1.281,1.281,0,0,0-1.285-1.285Zm-1.285-4.5h2.571A11.569,11.569,0,0,0,17.995,6.427V9A9,9,0,0,1,26.992,17.995Zm-5.141,0h2.571a6.429,6.429,0,0,0-6.427-6.427v2.571A3.859,3.859,0,0,1,21.851,17.995Z"
                        transform="translate(-6.427 -6.427)"
                        fill="#ff0245"
                      />
                    </svg>
                    <span>734 397 7779</span>
                  </p>
                </div>
                <h5>Hours</h5>
                <p>
                  {" "}
                  MON - FRI: 8AM — 7PM <br />
                  SATURDAY: 9AM — 6PM
                  SUNDAY: 8AM — 6PM
                </p>
              </div>
            </div>
            <div className="col col-md-8 contact-sec2-img book-appt-map">
              <label>&nbsp;</label>
              <iframe
                width="705"
                height="475"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                src={map}
              ></iframe>
              <label>&nbsp;</label>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReferralForm
