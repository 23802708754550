import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const Appointment = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage {
        appointmentTitle
        appointmentContent {
          appointmentContent
        }
        appointments {
          label
          url
        }
      }
    }
  `)
  const { appointmentTitle, appointmentContent, appointments } =
    data.contentfulHomePage
  
  return (
    <section className="appointment-sec">
      <div className="container">
        <div className="row">
        <h2>{appointmentTitle}</h2>
        <p>{appointmentContent.appointmentContent}</p>
        <div className="col col-md-6 appointment-sec-l d-flex">
          <ul>
            {appointments.map((item, i) => {
              if (i % 2 === 0) {
                return (
                  <a href={item.url}>
                    <li key={item.id}>
                      <button
                        type="button"
                        className="active"
                      >
                        {item.label}
                      </button>
                    </li>
                  </a>
                )
              }
              return null
            })}
          </ul>
        </div>
        <div className="col col-md-6 appointment-sec-l d-flex">
          <ul>
            {appointments.map((item, i) => {
              if (i % 2 !== 0) {
                return (
                  <li key={item.id}>
                    <a href={item.url}>
                      <button
                        className="active"
                        type="button"
                        target={`.appointment-sec-box${i + 1}`}
                      >
                        {item.label}
                      </button>
                      </a>
                  </li>
                )
              }
              return null
            })}
          </ul>
        </div>
        </div>
      </div>
    </section>
  )
}

export default Appointment
