import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import CloseIcon from "../../../static/assets/images/close-icon.svg"
import { Link } from "gatsby"

const SurgeriesSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulSurgeriesPage {
        surgeriesTitle
        surgeries {
          id
          title
          content {
            content
          }
          fullDescription {
            fullDescription
          }
          url
          label
        }
      }
    }
  `)
  const { surgeriesTitle, surgeries } = data.contentfulSurgeriesPage
  const [idx, setIdx] = useState(-1)
  return (
    <section className="surgery-page-sec1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col">
            <h1>{surgeriesTitle}</h1>
          </div>
          <div className="surgeries-outer-wrapper">
            <div className="surgeries-card-wrapper">
              {surgeries.slice(0, 3).map(surgery => (
                <div
                  className={`col col-md-4 surgery-page-sec1-inner`}
                  key={surgery.id}
                >
                  <div
                    className="surgery-page-box"
                  >
                    <Link to={surgery.url}><h2>{surgery.title}</h2></Link>
                    <p className="initial-text">{surgery.content.content}</p>
                    <p className="hidden-text">
                      {surgery.fullDescription.fullDescription}
                    </p>
                    <div className="card-btn-wrapper">
                      <Link className="link-btn" to={surgery.url}>
                        {surgery.label}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.243"
                          height="13.501"
                          viewBox="0 0 20.243 13.501"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-round-forward"
                            data-name="Icon ionic-ios-arrow-round-forward"
                            d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                            transform="translate(-7.875 -11.252)"
                            fill="#262b4a"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="surgeries-card-wrapper">
              {surgeries.slice(3, 6).map(surgery => (
                <div
                  className={`col col-md-4 surgery-page-sec1-inner`}
                  key={surgery.id}
                >
                  <div
                    className="surgery-page-box"
                  >
                    <Link to={surgery.url}><h2>{surgery.title}</h2></Link>
                    <p className="initial-text">{surgery.content.content}</p>
                    <p className="hidden-text">
                      {surgery.fullDescription.fullDescription}
                    </p>
                    <div className="card-btn-wrapper">
                      <Link className="link-btn" to={surgery.url}>
                        {surgery.label} 
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.243"
                          height="13.501"
                          viewBox="0 0 20.243 13.501"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-round-forward"
                            data-name="Icon ionic-ios-arrow-round-forward"
                            d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                            transform="translate(-7.875 -11.252)"
                            fill="#262b4a"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="surgeries-card-wrapper">
              {surgeries.slice(6).map(surgery => (
                <div
                  className={`col col-md-4 surgery-page-sec1-inner ${
                    idx === surgery.id && "active"
                  }`}
                  key={surgery.id}
                >
                  <div
                    className="surgery-page-box"
                    onClick={() => setIdx(surgery.id)}
                  >
                    <Link to={surgery.url}>
                      <h2>{surgery.title}</h2>
                    </Link>
                    <p className="initial-text">{surgery.content.content}</p>
                    <p className="hidden-text">
                      {surgery.fullDescription.fullDescription}
                    </p>
                    <div className="card-btn-wrapper">
                      <Link className="link-btn" to={surgery.url}>
                        {surgery.label}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.243"
                          height="13.501"
                          viewBox="0 0 20.243 13.501"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-round-forward"
                            data-name="Icon ionic-ios-arrow-round-forward"
                            d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                            transform="translate(-7.875 -11.252)"
                            fill="#262b4a"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SurgeriesSection
