import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import MetaCards from "../components/common/meta-cards"
import Hero from "../components/article/hero"
import FeaturedSection from "../components/article/feature"
import FeaturedMobile from "../components/article/feature-mobile"
import ArticleContent from "../components/article/content"
import FAQs from "../components/article/faq"
import TipsFromTeam from "../components/shared/tips_from_team"
import Visit from "../components/shared/visit"

const Article = props => {
  useEffect(() => {
    document.querySelector("body").classList.add("resource-inner-page")
  }, [])

  return (
    <Layout>
      <Seo title={props.data.articles.title} />
      <MetaCards data={props.data.articles.webMeta} />
      <Hero articles={props.data.articles} />
      <FeaturedSection articles={props.data.articles} />
      {props.data.articles.content.map(item => (
        <ArticleContent key={item.id} item={item} />
      ))}
      <div className="mobileOnly">
          <FeaturedMobile articles={props.data.articles} />
      </div>
      <div className={props.data.articles.hideResources && "hideFAQ"}>
        <TipsFromTeam data={props.data.articles.tipsFromTeam} services={true} />
      </div>
      <Visit data={props.data.articles.scheduleAVisit} />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ($slug: String!) {
    articles: contentfulResourceArticlesShort(slug: { eq: $slug }) {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      id
      image {
        file {
          url
        }
      }
      category
      title
      slug
      excerpt {
        excerpt
      }
      date
      keywords
      introText {
        raw
      }
      rightSideBar {
        title
        links {
          ... on ContentfulLinkTemplate {
            id
            label
            url
          }
        }
      }
      newPatients {
        image {
          file {
            url
          }
        }
        title
        content {
          content
        }
        linkLabel
        linkUrl
      }
      content {
        id
        fullWidth
        imageToLeft
        fullHeightImage
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              description
            }
          }
        }
      }
      hideResources 
      tipsFromTeam {
        title
        content
        tips {
          id
          label
          shortDescription
          url
          image {
            file {
              url
            }
          }
        }
      }

      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
        secondaryButtonUrl
        secondaryButtonLabel
      }
    }
  }
`
