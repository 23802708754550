import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import MetaCards from "../components/common/meta-cards"
import Hero from "../components/about/hero"
import Mission from "../components/about/mission"
import Values from "../components/about/values"
// import Quote from "../components/about/quote"
import Visit from "../components/shared/visit"
import Testimonials from "../components/shared/testimonials"
import Health from "../components/shared/health"

const AboutPage = ({ data }) => {
  return (
      <Layout>
        <Seo />
        <MetaCards data={data.contentfulAboutPage.webMeta}/>
        <Hero />
        <Mission />
        <Testimonials data={data.contentfulAboutPage} about={true} />
        <Values />
        {/* <Quote /> */}
        {/* <Health data={data.contentfulAboutPage} /> */}
        <Visit data={data.contentfulAboutPage.scheduleAVisit} />
      </Layout>
  )
}

export default AboutPage

export const query = graphql`
  {
    contentfulAboutPage {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      testimonialTitle
      testimonials {
        id
        testimonial {
          testimonial
        }
        client
      }
      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
        secondaryButtonUrl
        secondaryButtonLabel
      }
    }
  }
`